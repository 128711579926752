// Generated by Framer (cffb68d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-mzzOA"

const variantClassNames = {NUhXMXBOT: "framer-v-1wc9bhr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, i7NEtCqaR: title ?? props.i7NEtCqaR ?? "TESTIMONIALS"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, i7NEtCqaR, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "NUhXMXBOT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wc9bhr", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"NUhXMXBOT"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-cb454d22-0395-4276-9d88-1e913b7104a0, rgb(0, 113, 184))", borderBottomLeftRadius: 11, borderBottomRightRadius: 11, borderTopLeftRadius: 11, borderTopRightRadius: 11, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItU2VtaUJvbGQ=", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "600", "--framer-letter-spacing": "0.1em", "--framer-line-height": "110%", "--framer-text-alignment": "center"}}>TESTIMONIALS</motion.p></React.Fragment>} className={"framer-141phb2"} data-framer-name={"Text"} fonts={["Inter-SemiBold"]} layoutDependency={layoutDependency} layoutId={"AfEbO9yJP"} style={{"--framer-paragraph-spacing": "0px"}} text={i7NEtCqaR} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mzzOA.framer-14ad7wc, .framer-mzzOA .framer-14ad7wc { display: block; }", ".framer-mzzOA.framer-1wc9bhr { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: min-content; }", ".framer-mzzOA .framer-141phb2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mzzOA.framer-1wc9bhr { gap: 0px; } .framer-mzzOA.framer-1wc9bhr > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-mzzOA.framer-1wc9bhr > :first-child { margin-left: 0px; } .framer-mzzOA.framer-1wc9bhr > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 29
 * @framerIntrinsicWidth 128
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"i7NEtCqaR":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameru77o78MQs: React.ComponentType<Props> = withCSS(Component, css, "framer-mzzOA") as typeof Component;
export default Frameru77o78MQs;

Frameru77o78MQs.displayName = "Section Badge";

Frameru77o78MQs.defaultProps = {height: 29, width: 128};

addPropertyControls(Frameru77o78MQs, {i7NEtCqaR: {defaultValue: "TESTIMONIALS", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Frameru77o78MQs, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/hyOgCu0Xnghbimh0pE8QTvtt2AU.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/NeGmSOXrPBfEFIy5YZeHq17LEDA.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/oYaAX5himiTPYuN8vLWnqBbfD2s.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/lEJLP4R0yuCaMCjSXYHtJw72M.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/cRJyLNuTJR5jbyKzGi33wU9cqIQ.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/1ZFS7N918ojhhd0nQWdj3jz4w.woff2", weight: "600"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/A0Wcc7NgXMjUuFdquHDrIZpzZw0.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})